:root {
  font-size: 16px;
  background: black; }

@font-face {
  font-family: SegoeUI;
  src: url("./assets/fonts/SegoeUI/SegoeUI.ttf"); }

@font-face {
  font-family: SegoeUIItalic;
  src: url("./assets/fonts/SegoeUI/SegoeUIItalic.ttf"); }

@font-face {
  font-family: SegoeUIBold;
  src: url("./assets/fonts/SegoeUI/SegoeUIBold.ttf"); }

@font-face {
  font-family: SegoeUIBoldItalic;
  src: url("./assets/fonts/SegoeUI/SegoeUIBoldItalic.ttf"); }

@font-face {
  font-family: SegoeUISemiBold;
  src: url("./assets/fonts/SegoeUI/SegoeUISemiBold.ttf"); }

* {
  font-family: SegoeUI !important; }
