*::-webkit-scrollbar {
   width: 0,
}

* {
   -ms-overflow-style: none;
   scrollbar-width: none;
   /* Firefox */
}

body {
   background-color: #000000;
}

.bgImg {
   position: absolute;
   // top: 9%;
   // left: 42%;
   // transform: translate(-50%, -50%);
   right: 0;
   transform: translate(-70%, 30%);
}

.meetngreetBtnStyle {
   position: absolute;
   top: 28%;
   left: 27%;
   transform: translate(-50%, -50%);
}

.shopBtnStyle {
   position: absolute;
   top: 28%;
   left: 73%;
   transform: translate(-50%, -50%);
}

.menuStyle {
   position: absolute;
   right: 5%;
   top: 2%;
}

.whiteFont {
   color: #FFFFFF;
   text-align: left;
}

.redFont {
   color: #EE255C;
}

.contentStyle {
   color: #FFFFFF;
   opacity: .6;
   font-size: 1rem !important;
   text-align: left;
}

.titleStyle {
   color: #FFFFFF;
   opacity: .8;
   font-size: 1.25rem !important;
   text-align: left;
   font-family: SegoeUI !important;
}

.categoryStyle {
   color: #FFFFFF;
   opacity: .8;
   font-size: 2.375rem !important;
   text-align: left;
   // margin-left: 36px !important;
}

.labelStyle {
   color: #FFFFFF;
   opacity: .6;
   font-size: 1.375rem !important;
   text-align: left;
   // margin-left: 36px !important;
}

.mainStyleVideo {
   // width: calc(65vw - 20px)!important;
}

.mainStyleMusic {
   // width: 88vw !important;
}

.mainStylePost {
   // width: 86vw !important;
}

.cardStyle {
   background: #3A3A3A;
   opacity: .8;
   border-radius: 24px;
   padding: 20px;
   width: 670px;
   height: 246px;
}

.cardTitle {
   color: #E1E1E1;
   opacity: 1;
   font-size: 1.5rem !important;
   text-align: left;
}

.cardSubTitle {
   color: #E1E1E1;
   opacity: .66;
   font-size: 1rem !important;
   text-align: left;
}

.cardContent {
   color: #ECECEC;
   opacity: 1;
   font-size: 1.125rem !important;
   text-align: left;
}

.videoCard {
   width: calc(100vw - 53.2vw);
   height: calc(100vh - 48.7vh);
   // padding-top: 56.25%;
   border-radius: 16px;
}

.audioCard {
   width: 16.125rem;
   padding-top: 56.25%;
   // height: 238px;
   border-radius: 16px;
   cursor: pointer;
}

.expAudioCard {
   width: calc(100vw - 86.55vw);
   height: calc(100vw - 86.55vw);
   // padding-top: 56.25%;
   // height: calc(100vh - 77.9vh);
   border-radius: 16px;
   cursor: pointer;
}

.dashboardAudioCardStyle {
   width: calc(100vw - 86.55vw);
   height: calc(100vw - 86.55vw);
   border-radius: 16px;

   // padding-top: 56.25%;
   // height: calc(100vh - 77.9vh);
   // width: calc(100vw - 71vw);
   // height: calc(100vh - 67.5vh);
   // padding-top: 56.25%;

   cursor: pointer;
}

.noMediaCard {
   color: #FFFFFF;
   opacity: 0.6;
   align-self: flex-end;
   font-size: 1.75rem !important;
}

.noMediaCardDivStyle {
   height: 40vh;
   display: flex;
   justify-content: center;
   align-self: end;
}

.dashboardAudioCardParentDiv {
   // width: 100%;
   margin-right: 20px !important;
}

.starIcon {
   position: absolute;
   bottom: 13%;
   left: 2%;
}

.videoPlayerIcon {
   position: absolute;
   bottom: 17%;
   right: 4%;
   opacity: 0.5;
}

.starIconForAudio {
   position: absolute;
   bottom: 4%;
   left: 2%;
   z-index: 1;
}

@media (min-width: 320px) and (max-width: 1024px) {
   .expAudioCard {
      // width: calc(100vw - 59.5vw);
      width: 158px;
      // height: calc(100vh - 81.2vh);
      height: 158px;
      border-radius: 16px;
      cursor: pointer;
   }

   .dashboardAudioCardStyle {
      width: calc(100vw - 56vw);
      height: calc(100vh - 85vh);
      padding-top: 56.25%;
      border-radius: 16px;
      cursor: pointer;
   }

   .videoPlayerIcon {
      bottom: 21%;
      right: 7%;
   }

   .noMediaCardDivStyle {
      height: 25vh;
   }
}

.mainContainer {
   margin-left: 50px;
   background-color: #000000;
   width: 94%;
}

.latestVideoContainer {
   padding: 155px 20px 20px 20px;
}

@media (min-width: 320px) and (max-width: 1024px) {
   .videoCard {
      width: calc(100vw - 15vw);
      // width: 331.5px;
      padding-top: 56.25%;
      height: calc(100vh - 79vh);
      // height: 236.31px;
      border-radius: 16px;
      background-size: contain !important;
   }

   .categoryStyle {
      font-size: 1.5rem !important;
      // margin-left: 36px !important;
   }

   .labelStyle {
      font-size: 0.875rem !important;
      // margin-left: 36px !important;
   }

   .titleStyle {
      font-size: 0.75rem !important;
   }

   .contentStyle {
      font-size: 0.625rem !important;
   }

   .audioCard {
      width: 9.875rem;
      padding-top: 56.25%;
      // height: 158px;
      border-radius: 16px;
   }

   .mobileScrollVideo {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      margin-top: 2px !important;
   }

   .scrollEffectForSearch {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      margin-top: 2px !important;
   }

   .mobileScrollMusic {
      flex-wrap: nowrap !important;
      overflow-x: scroll;
      padding-top: 10px;
      margin: 0px !important;
   }

   .cardTitle {
      font-size: 14px !important;
   }

   .cardSubTitle {
      font-size: 10px !important;
   }

   .cardContent {
      font-size: 14px !important;
      text-align: left;
   }

   .cardStyle {
      background: #3A3A3A !important;
      opacity: .8;
      border-radius: 24px !important;
      padding: 0px !important;
      width: 329px;
      height: 133px;
   }

   .mainContainer {
      margin-left: 0px !important;
      width: 96%;
   }

   .latestVideoContainer {
      padding: 20px 20px 20px 20px;
   }
}

@media (min-width: 1024px) {
   .mainContainer {
      // margin-left: 30px;
   }

   // .mainStyleVideo {
   //    width: calc(65vw - 20px) !important;
   // }

   .mainStyleMusic {
      width: calc(80vw + 123px) !important;
   }

   .mainStylePost {
      width: calc(80vw + 123px) !important;
   }

   .mobileScrollVideo {
      flex-wrap: nowrap !important;
      margin-top: 2px !important;
      overflow: hidden;
      overflow-x: scroll;
      width: calc(100vw - 180px) !important;
   }

   .scrollEffectForSearch {
      flex-wrap: nowrap !important;
      margin-top: 2px !important;
      overflow: hidden;
      overflow-x: scroll;
      width: calc(88vw - 20px) !important;
   }

   .mobileScrollMusic {
      flex-wrap: nowrap !important;
      // width: 86vw !important;
      overflow: hidden;
      overflow-x: scroll;
      margin: 0px !important;
      width: calc(100vw - 175px) !important;
   }

   .desktopPostDetails {
      width: calc(100vw - 145px) !important;
   }
}

@media (min-width: 1848px) {
   .mainStylePost {
      width: calc(80vw + 10px) !important;
   }

   .mainStyleMusic {
      width: calc(80vw) !important;
   }

   // .mainStyleVideo {
   //    width: 59vw !important;
   // }

   .mobileScrollVideo {
      width: calc(80vw - 27px) !important;
   }

   .scrollEffectForSearch {
      width: 79vw !important;
   }

   .mobileScrollMusic {
      width: calc(80vw - 20px) !important;
      // width: 80vw !important;
      margin: 0px !important;
   }

   .desktopPostDetails {
      width: calc(80vw + 20px) !important;
   }
}

.desktopPostDetails::-webkit-scrollbar {
   width: 0;
}

.desktopPostDetails {
   margin-top: 10px;
}