.mainBox{
    display: flex;
    flex-direction: row;
    // border: 2px solid saddlebrown;
}
.imageBox{
    margin:60px;
    // border: 2px solid springgreen;
}
.image{
    border-radius: 25px;
}
.MusicDetailsSection{
    margin-top:60px;
    font-family: 'SegoeUI';
    font-size: 15px;
    font-weight: 700;
    // border: 2px solid salmon;
    text-transform: capitalize;

}
.detailLayout{
display:inline-block;
width:150px;
}
.detailLayouto{
    margin-left: 50px;
    width:150px;
}